import baseService from "./baseService";

const resource = "/rest/v1";

export function listDocumentTypes() {
  return baseService.get(`${resource}/general/list-document-type`);
}

export function listPersonTypes() {
  return baseService.get(`${resource}/general/list-person-type`);
}

export function listStudyLevel() {
  return baseService.get(`${resource}/general/list-study-level`);
}

export function listEthnicity() {
  return baseService.get(`${resource}/general/list-ethnicity`);
}
