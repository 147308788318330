import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            name: "login",
            path: "/login",
            component: () =>
                import ("@/views/Login"),
        },
        {
            path: "/",
            redirect: "/components",
            component: () =>
                import ("@/layouts/BasicLayout"),
            meta: {requiresLogin: true},
            children: [{
                name: "components",
                path: "/",
                component: () =>
                    import ("@/views/Components"),
                meta: {requiresLogin: true},
            },
                {
                    name: "account.user",
                    path: "/account/user",
                    component: () =>
                        import ("@/views/AccountUser"),
                    meta: {requiresLogin: true},
                },
                {
                    name: "generalConfig",
                    path: "general-config",
                    component: () =>
                        import ("@/views/generalConfig/Index"),
                },
                {
                    name: "alertList",
                    path: "alert-list",
                    component: () =>
                        import ("@/views/AlertsList"),
                },
            ],
        },
        {
            path: "generalConfig",
            redirect: "/generalConfig",
            component: () =>
                import ("@/layouts/BasicLayout"),
            meta: {requiresLogin: true},
            children: [{
                name: "generalConfig.createRole",
                path: "create-role",
                component: () =>
                    import ("@/views/generalConfig/CreateRole"),
            },
                {
                    name: "generalConfig.editRoles",
                    path: "create-role",
                    component: () =>
                        import ("@/views/generalConfig/EditRoles"),
                },
                {
                    name: "generalConfig.createUser",
                    path: "create-role",
                    component: () =>
                        import ("@/views/generalConfig/CreateUser"),
                },
                {
                    name: "generalConfig.editUser",
                    path: "create-role",
                    component: () =>
                        import ("@/views/generalConfig/EditUser"),
                },
            ],
        },
        {
            path: "/census",
            redirect: "/census/dashboard",
            component: () =>
                import ("@/layouts/Layout"),
            meta: {requiresLogin: true},
            children: [{
                name: "census.dashboard",
                path: "dashboard",
                component: () =>
                    import ("@/views/census/Dashboard"),
            },
                {
                    name: "census.reports",
                    path: "reports",
                    component: () =>
                        import ("@/views/census/Reports"),
                },
                {
                    name: "census.searchComuner",
                    path: "searchComuner",
                    component: () =>
                        import ("@/views/census/SearchComuner"),
                },
                {
                    name: "users.index",
                    path: "users",
                    component: () =>
                        import ("@/views/users/index"),
                },
            ],
        },
        {
            path: "/humansRights",
            redirect: "/humansRights/dashboard",
            component: () =>
                import ("@/layouts/Layout"),
            meta: {requiresLogin: true},
            children: [{
                name: "humansRights.dashboard",
                path: "dashboard",
                component: () =>
                    import ("@/views/HumansRights/Dashboard"),
            },
                {
                    name: "humansRights.valueReferences",
                    path: "value-references",
                    component: () =>
                        import ("@/views/HumansRights/ValueReferences"),
                },
                {
                    name: "humansRights.reports",
                    path: "reports",
                    component: () =>
                        import ("@/views/HumansRights/Reports"),
                },
                {
                    name: "humansRights.list",
                    path: "list-of-cases",
                    component: () =>
                        import ("@/views/HumansRights/ListOfCases"),
                },
                {
                    name: "humansRights.createCase",
                    path: "create/case/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/CreateCase"),
                    props: true,
                },
                {
                    name: "humansRights.editCase",
                    path: "edit/case/:id",
                    component: () =>
                        import ("@/views/HumansRights/EditCase"),
                },
                {
                    name: "humansRights.editDisharmony",
                    path: "edit/case/:id/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/EditDisharmony"),
                    props: true,
                },
                {
                    name: "humansRights.listDisharmonyType",
                    path: "list-disharmony/type",
                    component: () =>
                        import ("@/views/HumansRights/ListDisharmonyType"),
                },
                {
                    name: "humansRights.createDisharmony",
                    path: "list-disharmony/type/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/CreateDisharmony"),
                    props: true,
                },
                {
                    name: "humansRights.editDisharmony",
                    path: "edit/disharmony/:id/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/EditDisharmony"),
                    props: true,
                },
                {
                    name: "humansRights.listEventType",
                    path: "list-event/type",
                    component: () =>
                        import ("@/views/HumansRights/ListEventType"),
                },
                {
                    name: "humansRights.createEvent",
                    path: "create/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/CreateEvent"),
                    props: true,
                },
                {
                    name: "humansRights.editEvent",
                    path: "edit/event/:id/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/EditEvent"),
                    props: true,
                },
                {
                    name: "humansRights.listSubEventType",
                    path: "list-event-subtype",
                    component: () =>
                        import ("@/views/HumansRights/ListSubEventType"),
                },
                {
                    name: "humansRights.createSubEvent",
                    path: "create/subevent/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/CreateSubEvent"),
                    props: true,
                },
                {
                    name: "humansRights.editSubEvent",
                    path: "edit/subevent/:id/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/EditSubEvent"),
                    props: true,
                },
                {
                    name: "humansRights.listRequestClass",
                    path: "request-class",
                    component: () =>
                        import ("@/views/HumansRights/ListRequestClass"),
                },
                {
                    name: "humansRights.createRequestsClass",
                    path: "create/request-class",
                    component: () =>
                        import ("@/views/HumansRights/CreateRequestClass"),
                },
                {
                    name: "humansRights.editRequestClass",
                    path: "edit/requestclass/:id",
                    component: () =>
                        import ("@/views/HumansRights/EditRequestClass"),
                },
                {
                    name: "humansRights.listStates",
                    path: "list-states",
                    component: () =>
                        import ("@/views/HumansRights/ListStates"),
                },
                {
                    name: "humansRights.createState",
                    path: "create/state",
                    component: () =>
                        import ("@/views/HumansRights/CreateState"),
                },
                {
                    name: "humansRights.editStatus",
                    path: "edit/status/:id",
                    component: () =>
                        import ("@/views/HumansRights/EditState"),
                },
                {
                    name: "humansRights.listEventTypeRequires",
                    path: "list-type-requires",
                    component: () =>
                        import ("@/views/HumansRights/ListEventTypeRequires"),
                },
                {
                    name: "humansRights.createEventTypeRequires",
                    path: "create/event-types-requires",
                    component: () =>
                        import ("@/views/HumansRights/CreateEventTypeRequires"),
                },
                {
                    name: "humansRights.editEventTypeRequires",
                    path: "edit/event-type-requires/:id",
                    component: () =>
                        import ("@/views/HumansRights/EditEventTypeRequires"),
                },
                {
                    name: "humansRights.valueReferences",
                    path: "value-references/:isFemale?",
                    component: () =>
                        import ("@/views/HumansRights/ValueReferences"),
                    props: true,
                },
                {
                    name: "humansRights.graphicReports",
                    path: "graphic-reports",
                    component: () =>
                        import ("@/views/HumansRights/graphicReports/GraphicReports"),
                },
                {
                    name: "humansRights.createEventTypeRequiresOption",
                    path: "create/event-types-requires-option",
                    component: () =>
                        import ("@/views/HumansRights/CreateEventTypeRequiresOption"),
                },
                {
                    name: "humansRights.editEventTypeRequiresOption",
                    path: "edit/event-type-requires/:id",
                    component: () =>
                        import ("@/views/HumansRights/EditEventTypeRequiresOption"),
                },
            ],
        },
        {
            path: "/selfjustice",
            redirect: "/selfjustice/dashboard",
            component: () =>
                import ("@/layouts/Layout"),
            meta: {requiresLogin: true},
            children: [{
                name: "selfjustice.dashboard",
                path: "dashboard",
                component: () =>
                    import ("@/views/SelfJustice/Dashboard"),
            },
                {
                    name: "selfjustice.valueReferences",
                    path: "value-references",
                    component: () =>
                        import ("@/views/SelfJustice/ValueReferences"),
                },
                {
                    name: "selfjustice.listDisharmonyType",
                    path: "list-disharmony/type",
                    component: () =>
                        import ("@/views/SelfJustice/ListDisharmonyType"),
                },
                {
                    name: "selfjustice.listofdisharmonies",
                    path: "listofdisharmonies",
                    component: () =>
                        import ("@/views/SelfJustice/ListOfDisharmonies"),
                },
                {
                    name: "selfjustice.createdisharmony",
                    path: "createdisharmony",
                    component: () =>
                        import ("@/views/SelfJustice/CreateDisharmony"),
                },
                {
                    name: "selfjustice.createDisharmonyReferences",
                    path: "create/disharmony-references",
                    component: () =>
                        import ("@/views/SelfJustice/CreateDisharmonyReferences"),
                },
                {
                    name: "selfjustice.editDisharmonyReferences",
                    path: "disharmony-references/:id",
                    component: () =>
                        import ("@/views/SelfJustice/EditDisharmonyReferences"),
                },
                {
                    name: "selfjustice.updatedisharmony",
                    path: "updatedisharmony/:id",
                    component: () =>
                        import ("@/views/SelfJustice/EditDisharmony"),
                },
                {
                    name: "selfjustice.graphicReports",
                    path: "graphic-reports",
                    component: () =>
                        import ("@/views/SelfJustice/graphicReports/GraphicReports"),
                },
                {
                    name: "selfjustice.listReceptionType",
                    path: "list-reception/type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/ReceptionType/ListReceptionType"
                            ),
                },
                {
                    name: "selfjustice.createReceptionType",
                    path: "create/reception-type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/ReceptionType/CreateReceptionType"
                            ),
                },
                {
                    name: "selfjustice.editReceptionType",
                    path: "reception-type/:id",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/ReceptionType/EditReceptionType"
                            ),
                },
                {
                    name: "selfjustice.listPlaceCorrection",
                    path: "list-reception/type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/PlaceCorrection/ListPlaceCorrection"
                            ),
                },
                {
                    name: "selfjustice.createPlaceCorrection",
                    path: "create/reception-type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/PlaceCorrection/CreatePlaceCorrection"
                            ),
                },
                {
                    name: "selfjustice.editPlaceCorrection",
                    path: "reception-type/:id",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/PlaceCorrection/EditPlaceCorrection"
                            ),
                },
                {
                    name: "selfjustice.listCorrectionType",
                    path: "list-reception/type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/CorrectionType/ListCorrectionType"
                            ),
                },
                {
                    name: "selfjustice.createCorrectionType",
                    path: "create/reception-type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/CorrectionType/CreateCorrectionType"
                            ),
                },
                {
                    name: "selfjustice.editCorrectionType",
                    path: "reception-type/:id",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/CorrectionType/EditCorrectionType"
                            ),
                }, //#
                {
                    name: "selfjustice.listPenaltiesTracking",
                    path: "list-reception/type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/PenaltiesTracking/ListPenaltiesTracking"
                            ),
                },
                {
                    name: "selfjustice.createPenaltiesTracking",
                    path: "create/reception-type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/PenaltiesTracking/CreatePenaltiesTracking"
                            ),
                },
                {
                    name: "selfjustice.editPenaltiesTracking",
                    path: "reception-type/:id",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/PenaltiesTracking/EditPenaltiesTracking"
                            ),
                },
                {
                    name: "selfjustice.listVictimTracking",
                    path: "list-reception/type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/VictimTracking/ListVictimTracking"
                            ),
                },
                {
                    name: "selfjustice.createVictimTracking",
                    path: "create/reception-type",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/VictimTracking/CreateVictimTracking"
                            ),
                },
                {
                    name: "selfjustice.editVictimTracking",
                    path: "reception-type/:id",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/VictimTracking/EditVictimTracking"
                            ),
                },
                {
                    name: "selfjustice.headerLines",
                    path: "header-lines",
                    component: () =>
                        import (
                            "@/views/SelfJustice/components/ValueReferences/HeaderLines/HeaderLines"
                            ),
                },
            ],
        },
        {
            path: "/womenRights",
            redirect: "/womenRights/dashboard",
            component: () =>
                import ("@/layouts/Layout"),
            meta: {requiresLogin: true},
            children: [{
                name: "womenRights.dashboard",
                path: "dashboard",
                component: () =>
                    import ("@/views/WomenRights/Dashboard"),
            },
                {
                    name: "womenRights.reports",
                    path: "reports",
                    component: () =>
                        import ("@/views/WomenRights/Reports"),
                },
                {
                    name: "womenRights.list",
                    path: "list-of-cases",
                    component: () =>
                        import ("@/views/WomenRights/ListOfCases"),
                },
                {
                    name: "womenRights.case",
                    path: ":mode/case/:id?",
                    component: () =>
                        import ("@/views/WomenRights/Case"),
                },
                {
                    name: "womenRights.graphicReports",
                    path: "graphic-reports",
                    component: () =>
                        import ("@/views/WomenRights/graphicReports/GraphicReports"),
                },
            ],
        },
        {
            path: "/planning",
            redirect: "/planning/dashboard",
            component: () =>
                import ("@/layouts/Layout"),
            meta: {requiresLogin: true},
            children: [{
                name: "planning.dashboard",
                path: "dashboard",
                component: () =>
                    import ("@/views/Planning/Dashboard"),
            },
                {
                    name: "planning.plans",
                    path: "plans",
                    component: () =>
                        import ("@/views/Planning/Plans"),
                },
                {
                    name: "planning.createPlan",
                    path: "create/plan",
                    component: () =>
                        import ("@/views/Planning/CreatePlan"),
                },
                {
                    name: "planning.tracking",
                    path: "tracking/:planId",
                    component: () =>
                        import ("@/views/Planning/Tracking"),
                },
                {
                    name: "planning.graphicReports",
                    path: "graphic-reports",
                    component: () =>
                        import ("@/views/Planning/graphicReports/GraphicReports"),
                },
                {
                    name: "planning.valueReferences",
                    path: "value-references",
                    component: () =>
                        import ("@/views/Planning/ValueReferences"),
                },
                {
                    name: "planning.listCategories",
                    path: "list-categories",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/Categories/ListCategories"
                            ),
                },
                {
                    name: "planning.createCategories",
                    path: "create/categories",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/Categories/CreateCategories"
                            ),
                },
                {
                    name: "planning.editCategories",
                    path: "edit/categories/:id",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/Categories/EditCategories"
                            ),
                },
                {
                    name: "planning.listResponsableSystem",
                    path: "list-categories",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/ResponsableSystem/ListEntityResponsable"
                            ),
                },
                {
                    name: "planning.createEntityResponsable",
                    path: "create/responsable-system",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/ResponsableSystem/CreateEntityResponsable"
                            ),
                },
                {
                    name: "planning.editResponsableSystem",
                    path: "edit/responsable-system/:id",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/ResponsableSystem/EditEntityResponsable"
                            ),
                },
                {
                    name: "planning.listFundingSource",
                    path: "list-funding-source",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/FundingSource/ListFundingSource"
                            ),
                },
                {
                    name: "planning.createFundingSource",
                    path: "create/funding-source",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/FundingSource/CreateFundingSource"
                            ),
                },
                {
                    name: "planning.editFundingSource",
                    path: "edit/funding-source/:id",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/FundingSource/EditFundingSource"
                            ),
                },
                {
                    name: "planning.listGeocapas",
                    path: "list-geocapas",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/Geocapas/ListGeocapas"
                            ),
                },
                {
                    name: "planning.createGeocapa",
                    path: "create/geocapa",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/Geocapas/CreateGeocapa"
                            ),
                },

                {
                    name: "planning.editGeocapa",
                    path: "edit/geocapa",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/Geocapas/EditGeocapa"
                            ),
                },
                {
                    name: "planning.graphicReports",
                    path: "graphic-reports-geojson",
                    component: () =>
                        import (
                            "@/views/Planning/components/ValueReferences/Geocapas/GraphicReports"
                            ),
                },
            ],
        },
        {
            path: "/ordinary-justice",
            redirect: "/ordinary-justice/dashboard",
            component: () =>
                import ("@/layouts/Layout"),
            meta: {requiresLogin: true},
            children: [{
                name: "ordinary-justice.dashboard",
                path: "dashboard",
                component: () =>
                    import ("@/views/OrdinaryJustice/Dashboard"),
            },
                {
                    name: "ordinary-justice.cases",
                    path: "cases",
                    component: () =>
                        import ("@/views/OrdinaryJustice/OrdinaryJusticeCases"),
                },
                {
                    name: "ordinary-justice.create-case",
                    path: "cases/:mode-:type",
                    component: () =>
                        import ("@/views/OrdinaryJustice/Case"),
                },
                {
                    name: "ordinary-justice.edit-case",
                    path: "cases-edit:mode-:type-:id",
                    component: () =>
                        import ("@/views/OrdinaryJustice/Case"),
                },
                {
                    name: "ordinary-justice.graphicReports",
                    path: "graphic-reports",
                    component: () =>
                        import ("@/views/OrdinaryJustice/graphicReports/GraphicReports"),
                },
                {
                    name: "ordinary-justice.valueReferences",
                    path: "value-references",
                    component: () =>
                        import ("@/views/OrdinaryJustice/Components/ValueReferences"),
                },
                {
                    name: "ordinary-justice.listTypes",
                    path: "list-types-source",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/Types/ListTypes"
                            ),
                },
                {
                    name: "ordinary-justice.CreateTypes",
                    path: "create/types-source",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/Types/CreateTypes"
                            ),
                },
                {
                    name: "ordinary-justice.editTypes",
                    path: "edit/types-source",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/Types/EditTypes"
                            ),
                },
                {
                    name: "ordinary-justice.editSubTypes",
                    path: "edit/sub-types-source/:id",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/SubTypes/EditSubTypes"
                            ),
                },
                {
                    name: "ordinary-justice.listSubTypes",
                    path: "list-sub-types-source",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/SubTypes/ListSubTypes"
                            ),
                },
                {
                    name: "ordinary-justice.CreateSubTypes",
                    path: "create/sub-types-source",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/SubTypes/CreateSubTypes"
                            ),
                },
                {
                    name: "ordinary-justice.editSubTypes",
                    path: "edit/sub-types-source/:id",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/SubTypes/EditSubTypes"
                            ),
                },
                {
                    name: "ordinary-justice.listStatus",
                    path: "list-status",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/Status/ListStatus"
                            ),
                },
                {
                    name: "ordinary-justice.createStatus",
                    path: "create/status",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/Status/CreateStatus"
                            ),
                },
                {
                    name: "ordinary-justice.editStatus",
                    path: "edit/status/:id",
                    component: () =>
                        import (
                            "@/views/OrdinaryJustice/Components/ValueReferences/Status/EditStatus"
                            ),
                },
            ],
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (
        to.matched.some((record) => record.meta.requiresLogin) && !store.state.is_logged
    ) {
        console.log('from', from);
        from.name !== "login" && await store.dispatch('validateToken')
        next("/login");
    } else {
        next();
    }
});

export default router;
