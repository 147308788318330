import baseService from "./baseService";

const resource = "/rest/v1";

export function typeList() {
  return baseService.get(`${resource}/self_justice/disharmony`);
}

export function showDisharmony(id) {
  return baseService.get(`${resource}/self_justice/show/disharmony/${id}`);
}

export function updateDisharmonyReferences(data, id) {
  return baseService.put(`${resource}/self_justice/disharmony/update/${id}`, data);
}

export function createDisharmonyReferences(data) {
  return baseService.post(`${resource}/self_justice/disharmony/create`, data);
}


export function listInvestigationType() {
  return baseService.get(`${resource}/self_justice/investigation/type`);
}

export function showInvestigationType(id) {
  return baseService.get(`${resource}/self_justice/show/investigation/type/${id}`);
}

export function updateInvestigationType(data, id) {
  return baseService.put(`${resource}/self_justice/investigation/type/update/${id}`, data);
}

export function createInvestigationType(data) {
  return baseService.post(`${resource}/self_justice/investigation/type/create`, data);
}

export function listPenaltiesPlaces() {
  return baseService.get(`${resource}/self_justice/penalties/places`);
}

export function showPenaltiesPlaces(id) {
  return baseService.get(`${resource}/self_justice/show/penalties/places/${id}`);
}

export function updatePenaltiesPlaces(data, id) {
  return baseService.put(`${resource}/self_justice/penalties/places/update/${id}`, data);
}

export function createPenaltiesPlaces(data) {
  return baseService.post(`${resource}/self_justice/penalties/places/create`, data);
}

export function listPenaltiesTypes() {
  return baseService.get(`${resource}/self_justice/penalties/type`);
}

export function showPenaltiesTypes(id) {
  return baseService.get(`${resource}/self_justice/show/penalties/type/${id}`);
}

export function updatePenaltiesTypes(data, id) {
  return baseService.put(`${resource}/self_justice/penalties/type/update/${id}`, data);
}

export function createPenaltiesTypes(data) {
  return baseService.post(`${resource}/self_justice/penalties/type/create`, data);
}

export function listVictimTracking() {
  return baseService.get(`${resource}/self_justice/victim/categories`);
}

export function showVictimTracking(id) {
  return baseService.get(`${resource}/self_justice/show/victim/categories/${id}`);
}

export function updateVictimTracking(data, id) {
  return baseService.put(`${resource}/self_justice/victim/categories/update/${id}`, data);
}

export function createVictimTracking(data) {
  return baseService.post(`${resource}/self_justice/victim/categories/create`, data);
}

export function listPenaltiesTracking() {
  return baseService.get(`${resource}/self_justice/penalties/categories`);
}

export function showPenaltiesTracking(id) {
  return baseService.get(`${resource}/self_justice/show/penalties/categories/${id}`);
}

export function updatePenaltiesTracking(data, id) {
  return baseService.put(`${resource}/self_justice/penalties/categories/update/${id}`, data);
}

export function createPenaltiesTracking(data) {
  return baseService.post(`${resource}/self_justice/penalties/categories/create`, data);
}

export function listReferences() {
  return baseService.get(`${resource}/self_justice/reference-values`);
}

export function listDisharmony(params = {}) {
  return baseService.get(`${resource}/self_justice/case`, {
    params: params,
  });
}

export function storeEvent(eventData) {
  return baseService.post(`${resource}/self_justice/case/store`, eventData);
}

export function showCase(id) {
  return baseService.get(`${resource}/self_justice/case/show/${id}`);
}

export function updateEvent(id, eventData) {
  return baseService.put(
    `${resource}/self_justice/case/update/${id}`,
    eventData
  );
}

export function listNewsTypes() {
  return baseService.get(`${resource}/self_justice/case/type-news`);
}

export function storeNews(data) {
  return baseService.post(`${resource}/self_justice/case/news/store`, data);
}

export function updateCitationState(data) {
  return baseService.post(
    `${resource}/self_justice/case/news/update/citation`,
    data
  );
}

export function updateCaptureOrderState(data) {
  return baseService.post(
    `${resource}/self_justice/case/news/update/capture-order`,
    data
  );
}

export function storeReviewResponse(data) {
  return baseService.post(
    `${resource}/self_justice/case/news/store/review-response`,
    data
  );
}

export function caseChangeState(data) {
  return baseService.post(`${resource}/self_justice/case/update/status`, data);
}

// list news
export function listNews(params = {}) {
    return baseService.get(`${resource}/self_justice/news`, {
        params: params,
    });
}

// store header lines by territory
export function storeHeaderLines(data, territoryId) {
    return baseService.post(`${resource}/self_justice/header-lines/store/${territoryId}`, data);
}

// get header lines by territory
export function getHeaderLines(territoryId) {
    return baseService.get(`${resource}/self_justice/header-lines/${territoryId}`);
}

// update alert status
export function deactivateAlert(alertId) {
    return baseService.post(`${resource}/self_justice/alert/update/status/${alertId}`, {
      active : false
    });
}

export function getDashboardData() {
  return baseService.get(`${resource}/self_justice/dashboard-stats`);
}

