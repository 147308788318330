import Service from "./baseService";

const resource = "/api";

export function login(credentials) {
  return Service.post(`${resource}/authentication/`, credentials);
}

export function logout() {
  localStorage.clear();
}

export function setAccessToken(access_token) {
  localStorage.setItem("access", access_token);
}

export function getAccessToken() {
  return localStorage.getItem("access") || null;
}

export function authUser() {
  return Service.get(`${resource}/authenticated-user`);
}
