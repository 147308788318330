import baseService from "./baseService";

const resource = "/rest/v1";

export function getOrdinaryJusticeReferenceValue() {
  return baseService.get(`${resource}/ordinary-justice/list-reference-values`);
}

export function storeNewCase(data) {
  return baseService.post(`${resource}/ordinary-justice/store-case`, data);
}

// show all cases
export function listCases(params) {
  return baseService.get(`${resource}/ordinary-justice/list-cases`, {
    params : params
  });
}

//show a case by id
export function showCase(id) {
  return baseService.get(`${resource}/ordinary-justice/show-case/${id}`);
}
// update a case by id
export function updateCase(id, data) {
  return baseService.put(
    `${resource}/ordinary-justice/update-case/${id}`,
    data
  );
}

// store case state
export function storeCaseState(data) {
  return baseService.post(
    `${resource}/ordinary-justice/store-case-state`,
    data
  );
}

// list case states by case id
export function listCaseStates(id) {
  return baseService.get(`${resource}/ordinary-justice/list-case-states/${id}`);
}

// store news
export function storeNews(data) {
  return baseService.post(`${resource}/ordinary-justice/store-news`, data);
}

// list news by case id
export function listNews(id) {
  return baseService.get(`${resource}/ordinary-justice/list-news/${id}`);
}

// store audience tracking
export function storeAudienceTracking(data) {
  return baseService.post(
    `${resource}/ordinary-justice/store-audience-tracking`,
    data
  );
}

// list audience tracking by case id
export function listAudienceTracking(id) {
  return baseService.get(
    `${resource}/ordinary-justice/list-audience-tracking/${id}`
  );
}

export function listTypes() {
  return baseService.get(`${resource}/ordinary-justice/value-references/types`);
}

export function showTypes(id) {
  return baseService.get(`${resource}/ordinary-justice/show/value-references/types/${id}`);
}

export function updateTypes(data, id) {
  return baseService.put(`${resource}/ordinary-justice/value-references/types/update/${id}`, data);
}

export function createTypes(data) {
  return baseService.post(`${resource}/ordinary-justice/value-references/types/create`, data);
}

export function listSubTypes() {
  return baseService.get(`${resource}/ordinary-justice/value-references/sub-types`);
}

export function showSubTypes(id) {
  return baseService.get(`${resource}/ordinary-justice/show/value-references/sub-types/${id}`);
}

export function updateSubTypes(data, id) {
  return baseService.put(`${resource}/ordinary-justice/value-references/sub-types/update/${id}`, data);
}

export function createSubTypes(data) {
  return baseService.post(`${resource}/ordinary-justice/value-references/sub-types/create`, data);
}

export function listStates() {
  return baseService.get(`${resource}/ordinary-justice/value-references/states`);
}

export function showStates(id) {
  return baseService.get(`${resource}/ordinary-justice/show/value-references/states/${id}`);
}

export function updateStates(data, id) {
  return baseService.put(`${resource}/ordinary-justice/value-references/states/update/${id}`, data);
}

export function createStates(data) {
  return baseService.post(`${resource}/ordinary-justice/value-references/states/create`, data);
}

export function getDashboardData() {
  return baseService.get(`${resource}/ordinary-justice/dashboard-stats`);
}
