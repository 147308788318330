import { mapState } from "vuex";

export default {
  methods: {
    $can(permissionInCamelCase) {
      return (
        this.$isSuperAdmin() ||
        this.permissions.indexOf(permissionInCamelCase) !== -1
      );
    },
    $isSuperAdmin() {
      return this.user.is_superuser === true;
    },
    $canArray(permissionsArray) {
      let found = false;
      if (this.permissions.length > 0) {
        found = this.permissions.some((r) => permissionsArray.includes(r));
      }
      return found == true || this.$isSuperAdmin();
    },
  },
  computed: {
    ...mapState(["user", "permissions"]),
  },
};
