import Vue from "vue";
import Vuex from "vuex";
import { login, setAccessToken, logout, authUser } from "@/services/auth";
import router from "@/router";
import { syncDataSource } from "@/services/dataSource";
import { permissionsByRole, showUserCodename } from "@/services/configService";
import { listReferences } from "@/services/SelfJustice";
import { listPlanningReferences } from "@/services/Planning";
import { getOrdinaryJusticeReferenceValue } from "@/services/OrdinaryJustice";
import createPersistedState from "vuex-persistedstate";
import {
  listDocumentTypes,
  listEthnicity,
  listPersonTypes,
  listStudyLevel,
} from "@/services/General";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white",
    SidebarBg: "",
    user: {},
    is_logged: true,
    dataSource: {},
    permissions: [],
    is_loading: false,
    selectedComponent: "",
    selfJusticeReference: [],
    planningReference: [],
    ordinaryJusticeReference: [],
    generalReference: [],
    syncDataSourceFlag: false,
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_LOGIN_STATE(state, loginState) {
      state.is_logged = loginState;
    },
    SET_LOGIN_USER(state, user) {
      state.user = user;
    },
    SET_DATA_SOURCE(state, datasource) {
      state.dataSource = datasource;
    },
    SET_PERMISSION(state, permissions) {
      state.permissions = permissions;
    },
    SET_IS_LOADING(state, loading) {
      state.is_loading = loading;
    },
    SET_COMPONENT(state, selectedComponent) {
      state.selectedComponent = selectedComponent;
    },
    SET_SELF_JUSTICE_REFERENCE(state, referenceValues) {
      state.selfJusticeReference = referenceValues;
    },
    SET_PLANNING_REFERENCE(state, referenceValues) {
      state.planningReference = referenceValues;
    },
    SET_ORDINARY_JUSTICE_REFERENCE(state, referenceValues) {
      state.ordinaryJusticeReference = referenceValues;
    },
    SET_GENERAL_REFERENCE(state, referenceValues) {
      state.generalReference = referenceValues;
    },
    SET_SYNC_DATA_SOURCE_FLAG(state, flag) {
      state.syncDataSourceFlag = flag;
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        setAccessToken('');
        const userCredentials = await login(credentials);
        setAccessToken(userCredentials.data?.access);
        commit("SET_LOGIN_STATE", true);
        const response = await authUser();
        commit("SET_LOGIN_USER", response.data);
        const dataSourceResponse = await syncDataSource();
        if (response.data?.is_superuser === false) {
          dataSourceResponse.data.data.Territorio =
            dataSourceResponse.data.data.Territorio.filter(
              (item) => item.territorio_id === response.data.territory_id
            );
          let permissionsRole = await permissionsByRole(response.data?.role_id);
          let permissionsUser = await showUserCodename(response.data?.id);
          permissionsRole = permissionsRole?.data.map((item) => item.codename);
          permissionsUser = permissionsUser?.data?.user_permissions;
          commit("SET_PERMISSION", [...permissionsRole, ...permissionsUser]);
        }
        commit("SET_DATA_SOURCE", dataSourceResponse.data);
        router.push("/");
      } catch (e) {
        throw {
          status: e.response.status,
          error: e.response.error,
        };
      }
    },
    signOut({ commit }) {
      logout();
      commit("SET_LOGIN_STATE", false);
      router.push("/login");
    },
    async validateToken({ commit }) {
      try {
        const response = await authUser();
        commit("SET_LOGIN_STATE", true);
        commit("SET_LOGIN_USER", response.data);
        router.currentRoute.name === 'login' && await router.push('/');
      } catch (err) {
        if (err.response.status === 401) {
          commit("SET_LOGIN_STATE", false);
          router.currentRoute.name !== 'login' && await router.push('/login');
        }
      }
    },
    async syncDataSource({ commit }) {
      const response = await syncDataSource();
      commit("SET_DATA_SOURCE", response.data);
    },
    async appLoading({ commit }, state) {
      commit("SET_IS_LOADING", state);
    },
    setComponent({ commit }, state) {
      commit("SET_COMPONENT", state);
    },
    async getSelfJusticeReferenceValue({ commit }) {
      try {
        const response = await listReferences();
        commit("SET_SELF_JUSTICE_REFERENCE", response.data);
      } catch (e) {
        console.error(e);
      }
    },
    async getPlanningReferenceValue({ commit }) {
      try {
        const response = await listPlanningReferences();
        commit("SET_PLANNING_REFERENCE", response.data);
      } catch (e) {
        console.error(e);
      }
    },
    async getOrdinaryJusticeReferenceValue({ commit }) {
      try {
        const response = await getOrdinaryJusticeReferenceValue();
        commit("SET_ORDINARY_JUSTICE_REFERENCE", response.data);
        const generalReferenceValues = {
          documentTypes: (await listDocumentTypes())?.data,
          personTypes: (await listPersonTypes())?.data,
          ethnicity: (await listEthnicity())?.data,
          studyLevel: (await listStudyLevel())?.data,
        };
        commit("SET_GENERAL_REFERENCE", generalReferenceValues);
      } catch (e) {
        console.error(e);
      }
    },
  },
  getters: {
    allDatasource: (state) => state.dataSource,
    allPermissions: (state) => state.permissions,
    allPlanningReference: (state) => state.planningReference,
  },
});
