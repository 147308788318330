import baseService from "./baseService";

const resource = "/rest/v1/config_general";

export function permissionsList() {
  return baseService.get(`${resource}/permission/list`);
}

export function listRoles() {
  return baseService.get(`${resource}/roles/list`);
}

export function showUser(id) {
  return baseService.get(`${resource}/users/show/${id}`);
}

export function showUserCodename(id) {
  return baseService.get(`${resource}/users/show/codename/${id}`);
}

export function listUser() {
  return baseService.get(`${resource}/users/list`);
}

export function storeRoles(eventData) {
  return baseService.post(`${resource}/roles/store`, eventData);
}

export function storeUsers(eventData) {
  return baseService.post(`${resource}/users/store`, eventData);
}

export function updateRoles(id, eventData) {
  return baseService.put(`${resource}/roles/update/${id}`, eventData);
}

export function updateUsers(id, eventData) {
  return baseService.put(`${resource}/users/update/${id}`, eventData);
}

export function showRoles(id) {
  return baseService.get(`${resource}/roles/show/${id}`);
}

export function permissionsByRole(id) {
  return baseService.get(`${resource}/roles/permissions/${id}`);
}

export function permissionsByUser(id) {
  return baseService.get(`${resource}/user/permissions/${id}`);
}

export function syncPermissionByRole(id, permissions) {
  return baseService.post(
    `${resource}/roles/store/permissions/${id}`,
    permissions
  );
}

export function syncPermissionByUser(id, permissions) {
  return baseService.post(
    `${resource}/users/store/permissions/${id}`,
    permissions
  );
}
