import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";
Vue.use(Vuetify);

const theme = {
  primary: "#43794E",
  info: "#1e88e5",
  secondary: "#0F7E26",
  success: "#21c1d6",
  accent: "#fc4b6c",
  error: "#DD3333",
  default: "#563dea",
  background: "#ffffff",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
