<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
    <v-overlay :value="is_loading" z-index="99">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {},
  async created() {
    try {
      this.showLoader();
      await this.$store.dispatch("validateToken");
      if (this.is_logged) await this.$store.dispatch("syncDataSource");
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  computed: mapState(["is_loading", "is_logged"]),
};
</script>
