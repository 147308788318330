import Vue from "vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./plugins/base";
import loadingMixin from "./mixins/loadingMixin";
import Vuelidate from "vuelidate";
import moment from "moment";
import canMixin from "./utils/mixins/mixins";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib";

import "leaflet/dist/leaflet.css";

Vue.mixin(canMixin);
Vue.use(Vuelidate);
Vue.config.productionTip = false;

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY/MM/DD");
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY/MM/DD hh:mm A");
  }
});
Vue.filter("currencyCOP", function (value) {
  if (value) {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    }).format(value);
  }
  return 0;
});

Vue.mixin(loadingMixin);

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "es-CO",
  decimalLength: 0,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
