import baseService from "./baseService";

const resource = "/rest/v1";

export function censusStats() {
  return baseService.get(`${resource}/census-mngr/statics`);
}

export function syncDataSource() {
  return baseService.get(`${resource}/census-mngr/sync-datasource`);
}
