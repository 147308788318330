import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["appLoading"]),
    showLoader() {
      this.appLoading(true);
    },
    hideLoader() {
      this.appLoading(false);
    },
  },
};
