import baseService from "./baseService";

const resource = "/rest/v1";

export function listPlanningReferences() {
    return baseService.get(`${resource}/planning/reference-values`);
}

export function listPlansResponse({ page, size, sort, filters = {} } = {}) {
    return baseService.get(`${resource}/planning/all-plans`, {
        params: {
            page,
            size,
            sort,
            ...filters,
        },
    });
}
export function createPlan(plan) {
    return baseService.post(`${resource}/planning/create-plan`, plan);
}

export function getPlan(id) {
    return baseService.get(`${resource}/planning/plan/${id}`);
}
export function addTracking(tracking) {
    return baseService.post(`${resource}/planning/add-tracking`, tracking);
}

export function listCategories() {
    return baseService.get(`${resource}/planning/categories`);
}

export function showCategories(id) {
    return baseService.get(`${resource}/planning/show/categories/${id}`);
}

export function updateCategories(data, id) {
    return baseService.put(`${resource}/planning/categories/update/${id}`, data);
}

export function createCategories(data) {
    return baseService.post(`${resource}/planning/categories/create`, data);
}

export function listEntityResponsable() {
    return baseService.get(`${resource}/planning/entity/responsable`);
}

export function showEntityResponsable(id) {
    return baseService.get(`${resource}/planning/show/entity/responsable/${id}`);
}

export function updateEntityResponsable(data, id) {
    return baseService.put(
        `${resource}/planning/entity/responsable/update/${id}`,
        data
    );
}

export function createEntityResponsable(data) {
    return baseService.post(
        `${resource}/planning/entity/responsable/create`,
        data
    );
}

export function listFundingSource() {
    return baseService.get(`${resource}/planning/funding/source`);
}

export function showFundingSource(id) {
    return baseService.get(`${resource}/planning/show/funding/source/${id}`);
}

export function updateFundingSource(data, id) {
    return baseService.put(
        `${resource}/planning/funding/source/update/${id}`,
        data
    );
}

export function createFundingSource(data) {
    return baseService.post(`${resource}/planning/funding/source/create`, data);
}

export function getDashboardData() {
    return baseService.get(`${resource}/planning/dashboard-stats`);
}

// export function createGeoJson(data) {
//     return baseService.post(`${resource}/planning/geolayer/create`, data);
// }
export function getTypeArea() {
    return baseService.get(`${resource}/planning/type-area`);
}
export function listGeoCapas() {
    return baseService.get(`${resource}/planning/geolayer`);
}

export function listGeoCapasJson(id) {
    return baseService.get(`${resource}/planning/geolayer/${id}`);
}

export function listGeoCapasData(id) {
    return baseService.get(`${resource}/planning/geolayer/report/${id}`);
}

export function listGeoLayerMap(id) {
    return baseService.get(`${resource}/planning/geolayermap/${id}`);
}

export function createGeoJson(files) {
    return baseService.post(`${resource}/planning/geolayer/create`, files, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function updateGeoJson(files,id) {
    return baseService.put(`${resource}/planning/geolayer/update/${id}`, files, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}